import axios from 'axios'
const state = {
    loading: false,
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    },
    newParent: {
        listParent: [],
        parent_relation: null,
        lives_with: null,
    },
    //  {
    //     information: {
    //         title: {
    //             description: "title",
    //             value: "",
    //         },
    //         first_name: {
    //             description: "first name",
    //             value: "",
    //         },
    //         last_name: {
    //             description: "last name",
    //             value: "",
    //         },
    //         full_address: {
    //             description: "full address",
    //             value: "",
    //         },
    //         post_code: {
    //             description: "post code",
    //             value: "",
    //         },
    //         mobile_number: {
    //             description: "mobile number",
    //             value: "",
    //         },
    //         work_number: {
    //             description: "work number",
    //             value: "",
    //         },
    //         home_number: {
    //             description: "home number",
    //             value: "",
    //         },
    //         email: {
    //             description: "email",
    //             value: "",
    //         },
    //         nationality: {
    //             description: "nationality",
    //             value: "",
    //         },
    //         origin: {
    //             description: "origin",
    //             value: "",
    //         },
    //         occupation: {
    //             description: "occupation",
    //             value: "",
    //         },
    //     }
    // },
    parent: {
        name: "",
        image: null,
        sub_account: null,
        account_id: null,
        children: [],
    information: {
        username: {
            desc:"user name",
            value:""
          },
          phone: {
            desc:"phone number",
            value:""
          },
          password: {
            desc:"Password",
            value:""
          },
          
         
          
          id: {
            desc:"id",
            value:""
          },}
    }
}
const getters = {
    getTableOptions(state) {
        return state.tableOptions
    },
    getLoadingStatus(state) {
        return state.loading;
      },
      getParentData(state) {
        return state.parent;
      },
      getNewParentData(state) {
        return state.newParent;
      },
}
const mutations = {
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setLoadingStatus(state, status) {
        state.loading = status;
      },
    setParentInformation(state, parentInfo) {
        state.parent.name = parentInfo.name;
        state.parent.image = parentInfo.image;
        state.parent.sub_account = parentInfo.sub_account.id;
        state.parent.account_id = parentInfo.sub_account.account_id;
        state.parent.information.username.value = parentInfo.user.username;
        state.parent.information.phone.value = parentInfo.mobile_number;
        state.parent.information.password.value = parentInfo.user.password;
        state.parent.children = parentInfo.children;
        state.parent.information.id.value = parentInfo.user_id;
      },
      setNewParentInformation(state, parentInfo) {
        state.newParent.listParent = parentInfo.data;
        state.newParent.parent_relation = parentInfo.parent_relation;
        state.newParent.lives_with = parentInfo.lives_with;

        // state.parent.information.title.value = parentInfo.title;
        // state.parent.information.first_name.value = parentInfo.first_name;
        // state.parent.information.last_name.value = parentInfo.last_name;
        // state.parent.information.full_address.value = parentInfo.full_address;
        // state.parent.information.post_code.value = parentInfo.post_code;
        // state.parent.information.mobile_number.value = parentInfo.mobile_number;
        // state.parent.information.work_number.value = parentInfo.work_number;
        // state.parent.information.home_number.value = parentInfo.home_number;
        // state.parent.information.email.value = parentInfo.email;
        // state.parent.information.nationality.value = parentInfo.nationality;
        // state.parent.information.origin.value = parentInfo.origin;
        // state.parent.information.occupation.value = parentInfo.occupation;
      },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchParents({ commit }, payload) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
                paginated: true,
            };
            for (let key in payload.params) {
                if (payload.params[key]) {
                    params[key] = payload.params[key]
                }
            }
            const response = await axios.get('/student-parent', {
                params,
            });
            let tableHeaders = []
            let accessTableData = []
            // if (payload.endPoint == '/parent') {
            tableHeaders = ["name", "phone number", "Full Address", "user name", "password", "settings"]
            accessTableData = [ ["name"], ["mobile_number"], ["full_address"], ["user","username"], ["user","password"]]
            // }
            // else {
            //     tableHeaders = ["Id", "الاسم", "اسم المستخدم", "الاعدادات"]
            //     accessTableData = [["id"], ["name"], ["username"]]
            // }
            commit('setTableData', {
                tableHeaders,
                tableData: response.data.data.map((el)=>({...el,name: `${el.first_name} ${el.last_name}`})),
                accessTableData,
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchParent({ commit, state }, parentId) {
        try {
          console.log(parentId);
          commit("setLoadingStatus", true);
          const response = await axios.get(`/student-parent/${parentId}`);
          let responseData = response.data.data;
          commit("setParentInformation", responseData);
        } catch (err) {
          console.log("errrr", err);
        } finally {
          commit("setLoadingStatus", false);
        }
      },
      async fetchNewParent({ commit, state }, studentId) {
        try {
          commit("setLoadingStatus", true);
          const response = await axios.get(`/student-parent?student_id=${studentId}`);
          let responseData = response.data;
          commit("setNewParentInformation", responseData);
        } catch (err) {
          console.log("errrr", err);
        } finally {
          commit("setLoadingStatus", false);
        }
      },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}