import axios from 'axios'

const state = {
    grades: [],
    gradeInfo: {},
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getGrades(state) {
        return state.grades
    },
    getGradeInfo(state) {
        return state.gradeInfo
    },
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setGrades(state, grades) {
        state.grades = grades
    },
    setGradeInfo(state, gradeInfo) {
        state.gradeInfo = gradeInfo
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchGrades({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
                name: queryParams.nameSearch,
                term_id: queryParams.searchTermId,
                grade_type_id: queryParams.searchGradeTypeId,
            };
            if (queryParams.paginationValue) {
                params.page = queryParams.paginationValue;
            }
            if (!queryParams.nameSearch) {
                delete params.name;
            }
            if (!queryParams.searchTermId) {
                delete params.term_id;
            }
            if (!queryParams.searchGradeTypeId) {
                delete params.grade_type_id;
            }
            const response = await axios.get("/grade", {
                params,
            });
            commit('setTableData', {
                tableHeaders: [
                    // "id",
                    "name",
                    "term",
                    "class type",
                    "payment value",
                    "invoice type",
                    "settings",
                    
                ],
                tableData: response.data.grades,
                accessTableData: [
                    // ["id"],
                    ["name"],
                    ["term", "name"],
                    ["grade_type", "name"],
                    ["price"],
                    ["invoice_template","name"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchAllGrades({ commit,state }) {
        try {
            const grades = await axios.get("/grade", {
                params: {
                    size: 10000,
                },
            });
            commit('setGrades', grades.data.grades)
            console.log("grades",state.grades);

        }
        catch (err) { }

    },
    async fetchGradeInfo({ commit, state }, gradeId) {
        state.gradeInfo = {}
        try {
            const gradeInformation = await axios.get(`/grade/${gradeId}`)
            commit('setGradeInfo', gradeInformation.data.data);
            console.log('grade info', gradeInformation);
        }
        catch (err) {
            console.log('err', err);
        }
        finally {

        }
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}